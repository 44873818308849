/* menu style */

/* .sidenav li > a {
	color: #303a51;
	font-size: 15px;
	padding: 0 15px;
}
.sign {
	float: right;
} */
.nav-wrapper ul li:hover,
.menu-item:hover {
	/* background: #852013; */
	position: relative;
	color: white !important;
}

.red .menu-item a {
	color: white !important;
}

.menu-item a {
	color: white !important;
	/* color: #852013 !important; */
}

/* nav ul a:hover {
	background: #852013;
	color: white !important;
} */
.bottom-right-absolute-menu ol {
	list-style-type: none;
	margin: 0 auto;
	padding: 0;
}

.menu-item {
	position: relative;
}

/* .menu-item:hover .sub-menu-item a {
	color: white !important;
} */

/* .menu-item:hover a {
	color: black !important;
} */

.menu-item a {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
	/* color: #2abab0;
	line-height: 46px; */
	font-size: 20px;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	padding: 0 0px 10px;
	margin: 0px 10px;
}

.sub-menu-item a {
	color: white;
}

.sub-menu-item:hover a {
	color: #9e0b0f !important;
	background-color: black;
	/* font-weight: 500; */
}

.sub-menu a {
	color: white !important;
	font-weight: 300;
}

.sub-menu .menu-item {
	text-transform: uppercase;
	font-weight: 300;
	padding: 0;
	background: transparent;
	opacity: 0;
	transform-origin: bottom;
	animation: enter 0.2s ease forwards;
	width: 100%;
}

.sub-menu .menu-item:nth-child(1) {
	animation-duration: 0.2s;
	animation-delay: 0s;
}

.sub-menu .menu-item:nth-child(2) {
	animation-duration: 0.3s;
	animation-delay: 0.1s;
}

.sub-menu .menu-item:nth-child(3) {
	animation-duration: 0.4s;
	animation-delay: 0.2s;
}

.sub-menu .menu-item:nth-child(4) {
	animation-duration: 0.5s;
	animation-delay: 0.3s;
}

.sub-menu .menu-item:nth-child(5) {
	animation-duration: 0.6s;
	animation-delay: 0.4s;
}

.sub-menu .menu-item:nth-child(6) {
	animation-duration: 0.7s;
	animation-delay: 0.5s;
}

.sub-menu .menu-item:nth-child(7) {
	animation-duration: 0.8s;
	animation-delay: 0.6s;
}

/* .sub-menu .menu-item:hover {
	background: #F8B195;
} */
.sub-menu .menu-item a {
	padding: 15px;
	margin: 0;
	text-align: right;
}

@media screen and (max-width: 600px) {
	/* .sub-menu .menu-item {
		background: #c06c84;
	} */
}

@media screen and (max-width: 600px) {
	.menu {
		position: relative;
	}

	.menu:after {
		content: "";
		position: absolute;
		top: calc(50% - 2px);
		right: 1rem;
		width: 30px;
		height: 4px;
		background: #fff;
		box-shadow: 0 10px #fff, 0 -10px #fff;
	}

	.menu > ol {
		display: none;
		background: #f67280;
		flex-direction: column;
		justify-content: center;
		height: 100vh;
		animation: fade 0.2s ease-out;
	}

	.menu > ol > .menu-item {
		flex: 0;
		opacity: 0;
		animation: enter 0.3s ease-out forwards;
	}

	.menu > ol > .menu-item:nth-child(1) {
		animation-delay: 0s;
	}

	.menu > ol > .menu-item:nth-child(2) {
		animation-delay: 0.1s;
	}

	.menu > ol > .menu-item:nth-child(3) {
		animation-delay: 0.2s;
	}

	.menu > ol > .menu-item:nth-child(4) {
		animation-delay: 0.3s;
	}

	.menu > ol > .menu-item:nth-child(5) {
		animation-delay: 0.4s;
	}

	.menu > ol > .menu-item:nth-child(6) {
		animation-delay: 0.5s;
	}

	.menu > ol > .menu-item:nth-child(7) {
		animation-delay: 0.6s;
	}

	.menu > ol > .menu-item:nth-child(8) {
		animation-delay: 0.7s;
	}

	.menu > ol > .menu-item + .menu-item {
		margin-top: 0.75rem;
	}

	.menu > ol > .menu-item:after {
		left: auto;
		right: 1rem;
		bottom: calc(50% - 2px);
	}

	.menu > ol > .menu-item:hover {
		z-index: 1;
	}

	.menu:hover > ol {
		display: flex;
	}

	.menu:hover:after {
		box-shadow: none;
	}
}

.sub-menu {
	position: absolute;
	top: 100%;
	/* display: none; */
	z-index: 1;
	padding: 0;
	left: 50%;
	transform: translateX(-50%);
	background: black;
}

.menu-item:hover > .sub-menu {
	display: block;
}

@keyframes enter {
	from {
		opacity: 0;
		transform: scaleY(0.98) translateY(10px);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

@keyframes fade {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* End menu style */
