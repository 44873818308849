App {
	text-align: center;
	height: 5000px;
}

.scrollTop {
	position: fixed;
	width: 50px;
	height: 50px;
	bottom: 20px;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	cursor: pointer;
	animation: fadeIn 0.3s;
	transition: opacity 0.4s;
	opacity: 0.5;
	left: 20px;
	background-color: black;
	color: white;
	border-radius: 50%;
	font-size: 25px;
}

.scrollTop:hover {
	opacity: 1;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 0.5;
	}
}